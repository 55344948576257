import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import ProductGrid from "~/components/product-grid"
import ShopLayout from "~/components/shop-layout"
import useSketches from "~/hooks/use-sketches"

const CollectionTemplate = ({ data: { products, collection, coverImage } }) => {
  const sketches = useSketches()
  const { daisy, fern } = sketches

  const noContentSketchKey = {
    gifts: daisy,
    "gift-vouchers": fern,
  }

  function renderNoProducts() {
    return (
      <div className="flex flex-col items-center justify-center mx-auto">
        <h4 className="text-xl">Coming Soon!</h4>

        {noContentSketchKey[collection.handle] && (
          <GatsbyImage
            image={noContentSketchKey[collection.handle]}
            className="mt-10 mx-auto w-48"
            alt="HeartBee Flowers · Winchester"
          />
        )}
      </div>
    )
  }

  return (
    <>
      <Seo title={collection.title} description={collection.description} />
      <PageHero title={collection.title} image={coverImage.gatsbyImageData} />

      <ShopLayout>
        {collection.descriptionHtml && (
          <div
            className="prose lg:prose-lg w-full mx-auto mb-8"
            dangerouslySetInnerHTML={{
              __html: collection.descriptionHtml,
            }}
          />
        )}

        {!products.nodes.length && renderNoProducts()}

        {products.nodes.length > 0 && (
          <ProductGrid
            collection={collection}
            sketches={sketches}
            products={products.nodes}
          />
        )}
      </ShopLayout>
    </>
  )
}

export const query = graphql`
  query ($handle: String!) {
    coverImage: contentfulAsset(title: { eq: "Home Cover Image" }) {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
    collection: shopifyCollection(handle: { eq: $handle }) {
      id
      handle
      title
      description
      descriptionHtml
    }
    products: allShopifyProduct(
      filter: { productType: { eq: $handle }, status: { eq: "ACTIVE" } }
      sort: { order: ASC, fields: priceRangeV2___minVariantPrice___amount }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`

export default CollectionTemplate
