import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import ProductCard from "~/components/product-card"

const ProductGrid = ({ products, collection = { handle: "" }, sketches }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-6 xl:gap-x-10 gap-y-8 sm:mx-6 lg:mx-0 md:max-w-prose xl:max-w-none">
      {products.length && products.map(product => <ProductCard key={product.id} product={product} />)}

      {collection.handle === "mothers-day" && (
        <div className="md:col-span-2 relative flex items-center justify-center">
          <GatsbyImage
            image={sketches.leaf2}
            className="mx-auto w-80"
            alt={`${collection.title} · HeartBee Flowers · Winchester`}
          />
        </div>
      )}
    </div>
  )
}

export default ProductGrid
