import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { getPrice } from "~/utils"

const ProductCard = ({ product }) => {
  const { id, title, handle, productType, priceRangeV2, featuredImage } =
    product

  return (
    <Link
      key={id}
      to={`/shop/${productType}/${handle}`}
      className="relative hover:text-orange"
    >
      <div className="relative overflow-hidden rounded">
        <GatsbyImage
          alt={featuredImage.altText ?? title}
          image={featuredImage.gatsbyImageData}
          className="h-96 w-auto rounded transform transition ease-in-out duration-200 hover:scale-110"
        />
      </div>

      <div className="text-lg text-center mt-4 pointer-events-none">
        <p className="">{title}</p>
        {priceRangeV2 && (
          <p className="mt-1 text-sm">
            {getPrice(priceRangeV2.minVariantPrice.amount)}
          </p>
        )}
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
    handle
    productType
    featuredImage {
      id
      altText
      gatsbyImageData(height: 560, layout: CONSTRAINED, placeholder: BLURRED)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
`

export default ProductCard
